import React from 'react';

import Hero from 'components/Hero';
import Form from 'components/Enroll/Form';
import * as styles from './styles.module.scss';
import getEmail from 'utils/get-email';

function SuccessNotification() {
  return (
    <div className={styles.success}>
        <p className={styles.main}>Thank you for your message!</p>
        <p>We will be in touch with you soon.</p>
    </div>
  );
}

function FailureNotification() {
  const email = getEmail()

  return (
    <div className={styles.error}>
        <p><span className={styles.bold}>Oh no!</span> We're so sorry but we were unable to forward your message.</p>
        <p>
          Please send us your note directly to{' '}
          <a className={styles.emailLink} href={`mailto:${email}`} target="_blank" rel="noreferrer">{email}</a>
          {' '}and we will be in touch as soon as we receive it.
        </p>
    </div>
  );
}

function Intro() {
  return (
    <div className={styles.intro}>
      <h1 className={styles.main}>Join the waitlist!</h1>
      <p>Send us a note and we'll be in touch shortly.</p>
    </div>
  );
}

export default class EnrollPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { sendFailure: false, sendSuccess: false };
  }

  onSendSuccess() {
    this.setState({ sendSuccess: true });
  }

  onSendFailure() {
    this.setState({ sendFailure: true });
  }

  render() {
    const { sendSuccess, sendFailure } = this.state;

    return (
      <div className={styles.root}>
        <Hero id={styles.hero} img={this.props.heroImg}>
          <div>
            <div className={styles.name}>Contact us</div>
          </div>
        </Hero>
        {!sendSuccess && !sendFailure && <Intro />}
        {sendSuccess && <SuccessNotification />}
        {sendFailure && <FailureNotification />}
        {!sendSuccess && <Form onSendSuccess={() => this.onSendSuccess()} onSendFailure={() => this.onSendFailure()} />}
      </div>
    );
  }
}
