/**
 * @param {Object} data
 * @returns {Promise}
 */
export default function forwardContact(data) {
    return fetch('/api/forward', {
      method: 'post',
      body: JSON.stringify(data),
      headers: new Headers({ 'Content-Type': 'application/json' })
    });
}