import React from 'react';
import { graphql } from 'gatsby'
import Enroll from 'components/Enroll';
import Layout from 'components/Layout'

export default class EnrollPage extends React.Component {
  render() {
    return <Layout
      location={this.props.location}
      title={"Stonybrook Strings | Join our waitlist"}
      description={"Join the Stonybrook Strings waitlist! Contact us to start your child's musical journey."}
    >
      <Enroll heroImg={this.props.data.heroImg.childImageSharp.gatsbyImageData} />
    </Layout>;
  }
}

export const query = graphql`
  query EnrollImageQuery {
    heroImg: file(relativePath: { eq: "violin-hand-70.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    
  }
`;
