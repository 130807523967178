import React from 'react';

import Button from 'components/Button';
import * as styles from './styles.module.scss';
import forwardContact from 'utils/forward-contact';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { name: '', email: '', phone: '', about: '', source: '', showValidation: false};
  }

  onSubmit(event) {
    event.preventDefault();

    if (!this.isDataValid()) {
      return;
    }

    this.setState({ isPending: true });

    const { name, email, phone, about, source } = this.state;

    forwardContact({
      name, email, phone, about, source
    }).finally(() => {
      this.setState({ isPending: false });
    }).then((response) => {
      this.props.onSendSuccess && this.props.onSendSuccess(response);
    }).catch((error) => {
      this.props.onSendFailure && this.props.onSendFailure(error);
    });
  }

  handleInputChange({ target }) {
    let { name, value } = target;

    name === 'phone' && (value = this.handlePhoneInput(value));

    this.setState({ [name]: value });
  }

  isNameValid() {
    return !!this.state.name.trim();
  }

  isEmailValid() {
    return EMAIL_REGEX.test(this.state.email.trim());
  }

  isPhoneValid() {
    return PHONE_REGEX.test(this.state.phone.trim());
  }

  isDataValid() {
    const isValid = this.isEmailValid() && this.isNameValid() && this.isPhoneValid();

    this.setState({showValidation: !isValid });

    return isValid;
  }

  handlePhoneInput(val) {
    val = val.replace(/\D/g, '');

    const length = val.length,
      p1 = val.substr(0, 3),
      p2 = val.substr(3, 3),
      p3 = val.substr(6, 4);

    if (length > 0 && length <= 3) {
      return `(${p1}`;
    }

    if (length > 3 && length <= 6) {
      return `(${p1}) ${p2}`;
    }

    if (length > 6 && length <= 10) {
      return `(${p1}) ${p2}-${p3}`;
    }

    return val;
  }

  render() {
    const { showValidation, isPending } = this.state,
        handleInputChange = (ev) => this.handleInputChange(ev);

    return (
      <form className={`${styles.root} ${showValidation ? styles.showValidation : ''}`} onSubmit={(ev) => this.onSubmit(ev)}>
        <input
          type="text"
          name="name"
          className={this.isNameValid() ? null : styles.invalid}
          placeholder="Your name"
          autoCapitalize="off"
          autoComplete="name"
          autoCorrect="off"
          spellCheck="false"
          value={this.state.name}
          onChange={handleInputChange}
          disabled={isPending}
        />

        <input
          type="text"
          name="email"
          className={this.isEmailValid() ? null : styles.invalid}
          placeholder="Your email"
          autoCapitalize="off"
          autoComplete="email"
          autoCorrect="off"
          spellCheck="false"
          value={this.state.email}
          onChange={handleInputChange}
          disabled={isPending}
        />

        <input
          type="tel"
          name="phone"
          className={this.isPhoneValid() ? null : styles.invalid}
          placeholder="Your phone number"
          autoCapitalize="off"
          autoComplete="tel"
          autoCorrect="off"
          spellCheck="false"
          value={this.state.phone}
          disabled={isPending}
          onChange={handleInputChange}
        />

        <input
          type="text"
          name="source"
          placeholder="How did you hear about us?"
          autoCapitalize="off"
          autoCorrect="true"
          spellCheck="true"
          value={this.state.source}
          disabled={isPending}
          onChange={handleInputChange}
        />

        <textarea
          type="email"
          name="about"
          placeholder="Tell us a little about your child"
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="true"
          value={this.state.about}
          onChange={handleInputChange}
          disabled={isPending}
        />

        <Button className={styles.submit} text="SEND" withSpinner={isPending} />
      </form>
    );
  }
}
